import { Button } from "@/components/ui/button";
import { LoaderCircle } from "lucide-react";
import React from "react";

interface ConnectStepProps {
  title?: string;
  description?: React.ReactNode;
  children: React.ReactNode;
  nextLoading?: boolean;
  nextButtonDisabled?: boolean;
  nextButtonLabel?: string;
  noContainerPadding?: boolean;
  persistentScroll?: boolean;
  onClickNext?: () => void;
}

const ConnectStep: React.FC<ConnectStepProps> = ({
  title,
  description,
  nextLoading,
  nextButtonDisabled,
  nextButtonLabel,
  onClickNext,
  noContainerPadding,
  persistentScroll,
  children,
}) => {
  return (
    <>
      {title && (
        <h1 className="text-3xl font-semibold tracking-tight px-5">{title}</h1>
      )}
      {description && (
        <p className="text-sm text-muted-foreground px-5 py-2">{description}</p>
      )}
      <div
        className={`h-full  ${
          persistentScroll ? "overflow-y-scroll" : "overflow-y-auto"
        } ${noContainerPadding ? "" : "px-5"} ${
          nextButtonLabel ? "pb-10" : ""
        }`}
      >
        {children}
      </div>
      {nextButtonLabel && (
        <div className="mt-auto relative">
          <div className="pointer-events-none bg-gradient-to-b from-transparent to-background absolute bottom-full left-0 right-0 h-16"></div>
          <div className="mx-5 mb-5">
            <Button
              className="w-full px-5"
              size="lg"
              disabled={nextLoading || nextButtonDisabled}
              onClick={onClickNext}
            >
              {nextLoading && <LoaderCircle className="animate-spin mr-2" />}
              {!nextLoading && nextButtonLabel}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ConnectStep;
