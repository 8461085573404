import React, { Dispatch, createContext, useContext, useReducer } from "react";
import { Action, ActionType, ConnectState, Steps } from "./types";

export const ConnectContext = createContext<ConnectState | null>(null);
export const ConnectDispatchContext = createContext<Dispatch<Action> | null>(
  null
);

interface ConnectProviderProps {
  children: React.ReactNode;
}

export const ConnectProvider: React.FC<ConnectProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ConnectContext.Provider value={state}>
      <ConnectDispatchContext.Provider value={dispatch}>
        {children}
      </ConnectDispatchContext.Provider>
    </ConnectContext.Provider>
  );
};

const initialState: ConnectState = {
  currentStep: Steps.Init,
  progress: 0,
};

const getStepProgress = (step: Steps) => {
  const stepCount = 5;
  const currentStep = step.valueOf();
  return (currentStep / stepCount) * 100;
};

const reducer = (state: ConnectState, action: Action): ConnectState => {
  switch (action.type) {
    case ActionType.Back:
      return {
        ...state,
        currentStep: state.currentStep - 1,
        progress: getStepProgress(state.currentStep - 1),
      };
    case ActionType.Reset:
      return {
        ...state,
        currentStep: Steps.Init,
        progress: 0,
      };
    case ActionType.InitializeSession:
      return {
        ...initialState,
        currentStep: Steps.InstitutionSelect,
        progress: getStepProgress(Steps.InstitutionSelect),
        applicationId: action.payload.applicationId,
        appConfig: action.payload.appConfig,
        connectSessionId: action.payload.sessionId,
      };
    case ActionType.SetInstitution:
      return {
        ...state,
        currentStep: Steps.Permissions,
        progress: getStepProgress(Steps.Permissions),
        institution: action.payload,
      };
    case ActionType.AcceptPermissions:
      return {
        ...state,
        currentStep: Steps.Authentication,
        progress: getStepProgress(Steps.Authentication),
      };
    case ActionType.SetAuthenticated:
      return {
        ...state,
        currentStep: Steps.AccountSelect,
        progress: getStepProgress(Steps.AccountSelect),
        auth: {
          ...state.auth,
          isAuthenticated: true,
          isVerified: false,
          needsVerification: action.payload.needsVerification,
        },
      };
    case ActionType.SetVerified:
      return {
        ...state,
        currentStep: Steps.AccountSelect,
        progress: getStepProgress(Steps.AccountSelect),
        auth: {
          ...state.auth!,
          isVerified: true,
        },
      };
    case ActionType.SetSelectedAccounts:
      return {
        ...state,
        currentStep: Steps.Finished,
        progress: getStepProgress(Steps.Finished),
        accounts: {
          selected: action.payload.accounts,
        },
      };
    default:
      throw Error("Unknown action: " + action.type);
  }
};

export const useConnectState = () => {
  const state = useContext(ConnectContext);
  return state!;
};

export const useConnectDispatch = () => {
  const dispatch = useContext(ConnectDispatchContext);
  return dispatch!;
};
