import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  Check,
  DollarSign,
  Info,
  Landmark,
  LucideIcon,
  Menu,
} from "lucide-react";
import { useConnectDispatch, useConnectState } from "../ConnectContext";
import ConnectStep from "../components/ConnectStep";
import { ActionType } from "../types";

const PermissionCard = ({
  icon: Icon,
  title,
  appName,
  reason,
  items,
}: {
  icon: LucideIcon;
  title: string;
  appName: string;
  reason?: string;
  items: string[];
}) => (
  <Collapsible className="bg-secondary p-3 rounded-lg">
    <CollapsibleTrigger className="w-full">
      <div className="font-medium text-lg flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Icon className="h-5 w-5" /> {title}
        </div>
        <Info className="h-4 w-4" />
      </div>
    </CollapsibleTrigger>
    <CollapsibleContent className="CollapsibleContent">
      <div className="flex flex-col gap-2 mt-2">
        <hr className="border-primary" />
        <div className="flex flex-col gap-1">
          <div className="text-md font-medium">Datos solicitados</div>
          <div className="text-sm">
            <ul>
              {items.map((item) => (
                <li key={item} className="flex items-center gap-2">
                  <Check className="w-4 h-4" /> {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {reason && (
          <>
            <hr className="border-primary" />
            <div className="flex flex-col gap-1">
              <div className="text-md font-medium">Cómo son utilizados</div>
              <div className="text-sm">
                <span className="font-medium">{appName}</span> indicó que
                utiliza los datos de {title.toLowerCase()} para{" "}
                <span className="font-medium">{reason}</span>.
              </div>
            </div>
          </>
        )}
      </div>
    </CollapsibleContent>
  </Collapsible>
);

const PermissionsStep = () => {
  const { institution, appConfig } = useConnectState();
  const dispatch = useConnectDispatch();

  const handleNext = () => {
    dispatch({
      type: ActionType.AcceptPermissions,
    });
  };

  return (
    <ConnectStep
      nextButtonLabel="Continuar"
      onClickNext={handleNext}
      persistentScroll
    >
      <Avatar className="h-14 w-14 rounded-md border">
        <AvatarImage src={institution?.iconUrl || ""} />
        <AvatarFallback className="rounded-md">
          <Landmark />
        </AvatarFallback>
      </Avatar>

      <p className="text-xl mt-2 mb-4">
        <span className="font-medium">{appConfig?.name}</span> quiere acceder a
        estos datos financieros en{" "}
        <span className="font-medium">{institution?.name}</span>
      </p>

      <div className="flex space-y-6 flex-col">
        <div className="flex flex-1 flex-col gap-3">
          <PermissionCard
            icon={DollarSign}
            title="Balances"
            appName={appConfig!.name}
            items={["Balance actual", "Balance disponible", "Limites"]}
            reason={appConfig!.permissionReasons?.balances}
          />

          <PermissionCard
            icon={Menu}
            title="Transacciones"
            appName={appConfig!.name}
            items={["Historial de transacciones", "Transacciones futuras"]}
            reason={appConfig!.permissionReasons?.transactions}
          />

          <p className="text-muted-foreground text-sm">
            <span className="font-medium">{appConfig?.name}</span> no podrá
            realizar transacciones, y podrás revocar el acceso a tus datos en
            cualquier momento.
          </p>
        </div>
      </div>
    </ConnectStep>
  );
};

export default PermissionsStep;
