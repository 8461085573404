import type { FieldSpecification } from "@bridge/integration-core";
import type { AccountDto } from "@bridge/server/src/api/accounts/dto/account.dto";

export enum Steps {
  Init,
  InstitutionSelect,
  Permissions,
  Authentication,
  AccountSelect,
  Finished,
}

export interface ConnectState {
  currentStep: Steps;
  progress: number;
  applicationId?: string;
  appConfig?: {
    name: string;
    isSandbox: boolean;
    permissionReasons?: {
      balances?: string;
      transactions?: string;
    };
  };
  connectSessionId?: string;
  error?: string;
  institution?: {
    id: string;
    name: string;
    url: string;
    iconUrl?: string;
    authFields: FieldSpecification[];
  };
  auth?: {
    needsVerification: boolean;
    isAuthenticated: boolean;
    isVerified: boolean;
  };
  accounts?: {
    selected: AccountDto[];
  };
}

export enum ActionType {
  Back,
  InitializeSession,
  SetInstitution,
  AcceptPermissions,
  SetAuthenticated,
  SetVerified,
  SetSelectedAccounts,
  SetError,
  Reset,
}

interface _Action {
  type: ActionType;
  payload?: unknown;
}

export interface InitializeSessionAction extends _Action {
  type: ActionType.InitializeSession;
  payload: {
    applicationId: string;
    appConfig: {
      name: string;
      isSandbox: boolean;
      permissionReasons?: {
        balances?: string;
        transactions?: string;
      };
    };
    sessionId: string;
  };
}

export interface BackAction extends _Action {
  type: ActionType.Back;
}

export interface SetInstitutionAction extends _Action {
  type: ActionType.SetInstitution;
  payload: {
    id: string;
    name: string;
    url: string;
    iconUrl?: string;
    authFields: FieldSpecification[];
  };
}

export interface AcceptPermissionsAction extends _Action {
  type: ActionType.AcceptPermissions;
}

export interface SetAuthenticatedAction extends _Action {
  type: ActionType.SetAuthenticated;
  payload: {
    needsVerification: boolean;
  };
}

export interface SetVerifiedAction extends _Action {
  type: ActionType.SetVerified;
  payload: {
    verified: boolean;
  };
}

export interface SetSelectedAccountsAction extends _Action {
  type: ActionType.SetSelectedAccounts;
  payload: {
    accounts: AccountDto[];
  };
}

export interface SetErrorAction extends _Action {
  type: ActionType.SetError;
  payload: {
    error: string;
  };
}

export interface ResetAction extends _Action {
  type: ActionType.Reset;
}

export type Action =
  | InitializeSessionAction
  | BackAction
  | SetInstitutionAction
  | AcceptPermissionsAction
  | SetAuthenticatedAction
  | SetVerifiedAction
  | SetSelectedAccountsAction
  | SetErrorAction
  | ResetAction;
