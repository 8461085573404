import { Progress } from "@/components/ui/progress";
import { ErrorBoundary } from "@sentry/react";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronLeft, X } from "lucide-react";
import { useConnectDispatch, useConnectState } from "./ConnectContext";
import UnexpectedErrorFallback from "./components/UnexpectedError";
import { sendExit } from "./messages";
import InitStep from "./steps/0_Init";
import InstitutionSelectStep from "./steps/1_InstitutionSelect";
import PermissionsStep from "./steps/2_Permissions";
import AuthenticationStep from "./steps/3_Authentication";
import AccountSelectStep from "./steps/4_AccountSelect";
import FinishedStep from "./steps/5_Finished";
import { ActionType, Steps } from "./types";

const Connect = () => {
  const { currentStep, progress } = useConnectState();
  const dispatch = useConnectDispatch();

  const renderStep = () => {
    switch (currentStep) {
      case Steps.Init:
        return <InitStep />;
      case Steps.InstitutionSelect:
        return <InstitutionSelectStep />;
      case Steps.Permissions:
        return <PermissionsStep />;
      case Steps.Authentication:
        return <AuthenticationStep />;
      case Steps.AccountSelect:
        return <AccountSelectStep />;
      case Steps.Finished:
        return <FinishedStep />;
      default:
        return <h1>Step {currentStep} [Not implemented]</h1>;
    }
  };

  const handleBack = () => {
    dispatch({ type: ActionType.Back });
  };

  const handleClose = () => {
    sendExit();
    setTimeout(() => {
      dispatch({ type: ActionType.Reset });
    }, 0);
  };

  const NO_BACK_STEPS = [Steps.Init, Steps.AccountSelect, Steps.Finished];

  return (
    <div className="flex flex-col items-center justify-center h-full w-full fixed select-none bg-gray-500/[.8]">
      <div className="md:w-[360px] md:h-[608px] h-full w-full bg-background shadow-lg flex flex-col md:rounded-lg overflow-hidden">
        <ErrorBoundary fallback={UnexpectedErrorFallback}>
          <div className="flex flex-row items-center">
            <div className="flex-1 pl-3 py-3">
              {!NO_BACK_STEPS.includes(currentStep) && (
                <div className="p-2 cursor-pointer" onClick={handleBack}>
                  <ChevronLeft className="w-6 h-6" />
                </div>
              )}
            </div>
            <div className="flex-1">
              <Progress value={progress} />
            </div>
            <div className="flex-1 pr-3 py-3">
              <div className="p-2 cursor-pointer" onClick={handleClose}>
                <X className="ml-auto w-6 h-6" />
              </div>
            </div>
          </div>
          <AnimatePresence mode="wait">
            <motion.div
              key={currentStep}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{
                y: -10,
                opacity: 0,
              }}
              transition={{ duration: 0.2 }}
              className="h-full flex flex-col overflow-auto"
            >
              {renderStep()}
            </motion.div>
          </AnimatePresence>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Connect;
